import React, { useState, useEffect, useContext } from 'react'
import styles from './isrFileHistory.module.scss'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import FileHistoryHeader from '../../../../components/molecules/FileHistoryHeader/FileHistoryHeader'
import { NotifierContext } from '../../../../context/NotifierContext'
import { getUploadStatus, getDownLoadProcessedResultUrl } from '../../../../services/api/tcAuditService'
import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'
import dayjs from 'dayjs'
import AuditMonthStickyHeader from '../../../../components/atoms/AuditMonthStickyHeader/AuditMonthStickyHeader'
import InfoModal from '../../../../components/atoms/Modal/Modal'

const IsrFileHistory = () => {
  const [loading, setLoading] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const [filesHistory, setFilesHistory] = useState([])
  const [modalVisible, setModalVisibility] = useState(false)
  const [pageData, setPageData] = useState({
    current: 1,
    last: 1
  })
  const { notifyError } = useContext(NotifierContext)

  useEffect(() => {
    fetchFileUploadStatus(1)
  }, [])

  const fetchFileUploadStatus = async (page) => {
    try {
      setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'isr',
        page,
        perPage: 10,
        sortBy: 'auditAndUploadDate'
        // uploadedBy: 'AE'
      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleLoadMore = () => {
    const nextPage = pageData.current + 1
    fetchFileUploadStatus(nextPage)
  }

  const handleProcessingResultDownload = async (fileId) => {
    try {
      setProcessedResultLoader(true)
      const response = await getDownLoadProcessedResultUrl('isr', fileId)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  const categorizedFileHistory = filesHistory.reduce((accumulator, item) => {
    const monthName = dayjs(item.month).format('MMMM YYYY') // Format the date to month name
    if (!accumulator[monthName]) {
      accumulator[monthName] = []
    }
    accumulator[monthName].push(item)
    return accumulator
  }, {})

  return (
    <div className='container'>
      <FileHistoryHeader
        title='Installation status report'
        onRefresh={() => fetchFileUploadStatus(1)}
        loading={loading}
      />
      <div className='mt-5'>
        {loading
          ? <div>Loading....</div>
          : filesHistory.length === 0
            ? <div>No files uploaded!</div>
            : (
              <>
                {
                  Object.keys(categorizedFileHistory).map(monthYear => (
                    <div key={monthYear}>
                      <AuditMonthStickyHeader monthYear={monthYear} />
                      {categorizedFileHistory[monthYear].map((file, index) => {
                        return (
                          <div className={styles.fileStatusContainer} key={file.fileId}>
                            <FileStatus
                              uploadedBy={file.uploadedBy}
                              index={index + 1}
                              sectionResult={file.sectionResult}
                              status={file.status}
                              fileId={file.fileId}
                              uploadedOn={file.uploadedOn}
                              message={file.message}
                              onProcessingResultDownload={() => { handleProcessingResultDownload(file.fileId) }}
                              disableProcessedResult={processedResultLoader}
                              count={{
                                success: file.count?.success ?? '-',
                                failure: file.count?.failure ?? '-',
                                skipped: file.count?.skipped ?? '-'
                              }}
                              onClickFailureRows={() => setModalVisibility('failure')}
                              onClickSkippedRows={() => setModalVisibility('skipped')}
                            />
                          </div>
                        )
                      })}
                    </div>))
                  }

                {!(pageData.current === pageData.last) &&
                  <LoadMoreButton onClick={handleLoadMore} />}
              </>
              )}

      </div>
      <InfoModal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'failure' &&
          (
            <>
              1. When MR provided doesn’t exist
              <br /><br />

            </>
          )}
        {modalVisible === 'skipped' &&
          <>
            1. Rows are skipped when their DOS (Date of service) is newer than audit month (future installation)
            <br /> <br />
            2. Rows are skipped when the installation is already present in the O&M and there are no changes in either the reading day or the reading MR
            <br /> <br />
            3. Rows with INACTIVE status are skipped
            <br /> <br />
          </>}
      </InfoModal>
    </div>
  )
}

export default IsrFileHistory
