import React from 'react'
import Modal from '../../atoms/Modal/Modal'
import styles from './sectionWiseResults.module.scss'
import ResultItem from '../FileStatus/ResultItem'

const SectionWiseResults = ({
  onClickFailureRows = () => {},
  onClickSkippedRows = () => {},
  visible = false,
  onClose = () => {}
}) => {
  return (
    <Modal
      visible={visible}
      title='Section wise Results'
      styles={{
        dialog: { maxWidth: '65%', borderRadius: 5 },
        closeBtn: { filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(119deg) brightness(103%) contrast(102%)' },
        header: { background: styles.primaryColor },
        title: { color: 'white', fontSize: 24, fontWeight: 'bold' }
      }}
      hideFooter
      onModalClose={onClose}
    >
      <div className={`${styles.root}`}>
        <div className={styles.heading}>Section Code : 7370900</div>
        <div className={styles.resultStatusContainer}>
          <ResultItem type='all' number={6} />
          <ResultItem type='success' number={1} />
          <ResultItem type='failure' number={2} onClickInfo={onClickFailureRows} />
          <ResultItem type='skipped' number={3} onClickInfo={onClickSkippedRows} />
        </div>
      </div>
    </Modal>
  )
}

export default SectionWiseResults
