import React from 'react'
// import styles from './modal.module.scss'

const Modal = ({
  children,
  title,
  visible,
  onModalClose,
  okBtnDisabled = false,
  okBtnLoading = false,
  okAction = () => {},
  styles = {},
  hideFooter = false
}) => {
  const style = {
    display: visible ? 'block' : 'none'
  }
  return (
    <>
      <div
        className='modal export-excel-modal'
        id='exampleModal'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        style={style}
      >
        <div className='modal-dialog modal-dialog-centered' style={{ ...styles.dialog }}>
          <div className='modal-content' style={{ borderRadius: 8 }}>
            {/* ////////////////Header////////////////// */}
            <div
              className='modal-header'
              style={{ borderTopRightRadius: 7, borderTopLeftRadius: 7, ...styles.header }}
            >
              <h5
                className='modal-title'
                id='exampleModalLabel'
                style={{ ...styles.title }}
              >
                {title}
              </h5>
              <button
                onClick={onModalClose}
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                style={{ ...styles.closeBtn }}
              />
            </div>
            {/* //////////////////////////Body//////////////////// */}
            <div
              className='modal-body'
              style={{ maxHeight: '80vh', overflow: 'auto' }}
            >
              {children}
            </div>
            {/* ///////////////////////////Footer//////////////////// */}
            {!hideFooter &&
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={onModalClose}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  onClick={okAction}
                  disabled={okBtnDisabled}
                  className='btn btn-primary'
                >
                  OK{'\t'}
                  {okBtnLoading && (
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </button>
              </div>}
          </div>
        </div>
      </div>
      {visible && <div className='modal-backdrop fade show' />}
    </>
  )
}

export default Modal
