import React, { useState, useEffect, useContext } from 'react'
import styles from './zeroConsumptionFileHistory.module.scss'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import FileHistoryHeader from '../../../../components/molecules/FileHistoryHeader/FileHistoryHeader'
import { NotifierContext } from '../../../../context/NotifierContext'
import { getDownLoadProcessedResultUrl, getUploadStatus } from '../../../../services/api/tcAuditService'
import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'
import dayjs from 'dayjs'
import AuditMonthStickyHeader from '../../../../components/atoms/AuditMonthStickyHeader/AuditMonthStickyHeader'
import InfoModal from '../../../../components/atoms/Modal/Modal'

const ZeroConsumptionFileHistory = () => {
  const [loading, setLoading] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const [filesHistory, setFilesHistory] = useState([])
  const [pageData, setPageData] = useState({
    current: 1,
    last: 1
  })
  const [modalVisible, setModalVisibility] = useState(false)

  const { notifyError } = useContext(NotifierContext)

  useEffect(() => {
    fetchFileUploadStatus(1)
  }, [])

  const fetchFileUploadStatus = async (page) => {
    try {
      setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'zeroConsumption',
        page,
        perPage: 10,
        sortBy: 'auditAndUploadDate'
        // uploadedBy: 'AE'
      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleLoadMore = () => {
    const nextPage = pageData.current + 1
    fetchFileUploadStatus(nextPage)
  }

  const handleProcessingResultDownload = async (fileId) => {
    try {
      setProcessedResultLoader(true)
      const response = await getDownLoadProcessedResultUrl('zeroConsumption', fileId)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  const categorizedFileHistory = filesHistory.reduce((accumulator, item) => {
    const monthName = dayjs(item.month).format('MMMM YYYY') // Format the date to month name
    if (!accumulator[monthName]) {
      accumulator[monthName] = []
    }
    accumulator[monthName].push(item)
    return accumulator
  }, {})

  return (
    <div className='container'>
      <FileHistoryHeader
        title='ZeroConsumption'
        onRefresh={() => fetchFileUploadStatus(1)}
        loading={loading}
      />
      <div className='mt-5'>
        {loading
          ? <div>Loading....</div>
          : filesHistory.length === 0
            ? <div>No files uploaded!</div>
            : (
              <>
                {
                Object.keys(categorizedFileHistory).map(monthYear => (
                  <div key={monthYear}>
                    <AuditMonthStickyHeader monthYear={monthYear} />
                    {categorizedFileHistory[monthYear].map((file, index) => {
                      return (
                        <div className={styles.fileStatusContainer} key={file.fileId}>
                          <FileStatus
                            index={index + 1}
                            uploadedBy={file.uploadedBy}
                            status={file.status}
                            fileId={file.fileId}
                            sectionResult={file.sectionResult}
                            uploadedOn={file.uploadedOn}
                            message={file.message}
                            onProcessingResultDownload={() => { handleProcessingResultDownload(file.fileId) }}
                            disableProcessedResult={processedResultLoader}
                            count={{
                              success: file.count?.success ?? '-',
                              failure: file.count?.failure ?? '-',
                              skipped: file.count?.skipped ?? '-'
                            }}
                            onClickFailureRows={() => setModalVisibility('failure')}
                            onClickSkippedRows={() => setModalVisibility('skipped')}
                          />
                        </div>
                      )
                    })}
                  </div>
                ))

                }
                {!(pageData.current === pageData.last) &&
                  <LoadMoreButton onClick={handleLoadMore} />}
              </>
              )}

      </div>
      <InfoModal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'failure' &&
          (
            <>
              1. When installation with matching Account ID is not found in their O&M
              <br /><br />
              2. When TC with matching number is not found in their O&M
              <br /><br />
              3. When reading day of installation and TC do not match
              <br /><br />
              4. When MR tagged to installation is not tagged to TC
              <br /><br />
              5. When TC has already been audited for the current or future month
              <br /><br />
              6. When the TC to which the installation is already tagged has already been audited for the current or future month
              <br /><br />

            </>
          )}
        {modalVisible === 'skipped' &&
          <>
            1. Rows are skipped when the installation is already tagged to the specified TC for the specified audit month
            <br /> <br />
          </>}
      </InfoModal>
    </div>
  )
}

export default ZeroConsumptionFileHistory
